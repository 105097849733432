import { amplifyConfigure } from '@/amplify/amplify-configure.ts';
import { websocketStatusVar } from '@/components/websocket-status-var.ts';
import { shutdown as shutdownIntercom } from '@intercom/messenger-js-sdk';
import { setUser } from '@sentry/react';
import { CONNECTION_STATE_CHANGE, ConnectionState } from 'aws-amplify/data';
import { Hub } from 'aws-amplify/utils';
import {
  getApolloClient,
  setApolloClient,
} from './apollo/apollo-client-instance.ts';
import { invalidateSession } from './routes-utils/handle-authorized-data.ts';
import { makeApolloClient } from './apollo/apollo-client.ts';

let init = false;

export const setupAppGlobals = () => {
  if (!init) {
    amplifyConfigure();

    setApolloClient(makeApolloClient());

    Hub.listen('auth', async ({ payload }) => {
      invalidateSession();
      if (payload.event === 'signedOut') {
        window.mixpanel.reset();
        shutdownIntercom();
        setUser(null);
        await getApolloClient().clearStore();
      }
    });

    Hub.listen('api', ({ payload }) => {
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = (
          payload.data as { connectionState: ConnectionState }
        ).connectionState;

        websocketStatusVar(connectionState);
      }
    });

    init = true;
  }

  return;
};
